<template>
<div>
    <v-simple-table dense>
    <tbody>
    <v-card-subtitle class="font-weight-bold text-h6">Cadastro</v-card-subtitle>
    <tr v-if="ocr&&ocr.cadastro&&ocr.cadastro.cpf">
        <td class="font-weight-bold">CPF</td>
        <td>{{ formatCpf(ocr.cadastro.cpf) }}</td>
    </tr>
    <tr v-if="ocr&&ocr.cadastro">
        <td class="font-weight-bold">Data de nascimento</td>
        <td>{{ ocr.cadastro.dtn }}</td>
    </tr>
    <tr v-if="ocr&&ocr.cadastro">
        <td class="font-weight-bold">Nome</td>
        <td>{{ ocr.cadastro.nome }}</td>
    </tr>
    <tr v-if="ocr&&ocr.cadastro">
        <td class="font-weight-bold">Nome mãe</td>
        <td>{{ ocr.cadastro.nome_mae }}</td>
    </tr>

    <v-card-subtitle class="font-weight-bold text-h6">OCR</v-card-subtitle>
    <tr v-if="ocr&&ocr.ocr&&ocr.ocr.cpf">
        <td class="font-weight-bold">CPF</td>
        <td>{{ formatCpf(ocr.ocr.cpf) }}</td>
    </tr>
    <tr v-if="ocr&&ocr.ocr">
        <td class="font-weight-bold">Data de nascimento</td>
        <td>{{ ocr.ocr.dtn }}</td>
    </tr>
    <tr v-if="ocr&&ocr.ocr">
        <td class="font-weight-bold">Nome</td>
        <td>{{ ocr.ocr.nome }}</td>
    </tr>
    <tr v-if="ocr&&ocr.ocr">
        <td class="font-weight-bold">Nome mãe</td>
        <td>{{ ocr.ocr.nome_mae }}</td>
    </tr>

    <v-card-subtitle class="font-weight-bold text-h6">Biometria</v-card-subtitle>
    <tr v-if="biometria">
        <td class="font-weight-bold">Unico</td>
        <td>{{ biometria.unico }}</td>
    </tr>
    <tr v-if="biometria">
        <td class="font-weight-bold">Valid</td>
        <td>{{ biometria.valid }}</td>
    </tr>
    <tr v-if="biometria">
        <td class="font-weight-bold">Serpro</td>
        <td>{{ biometria.serpro }}</td>
    </tr>

    <v-card-subtitle class="font-weight-bold text-h6">Liveness</v-card-subtitle>
    <tr v-if="liveness&&liveness.aware">
        <td class="font-weight-bold">Score</td>
        <td>{{ liveness.aware.score }}</td>
    </tr>
    <tr v-if="liveness&&liveness.aware">
        <td class="font-weight-bold">Aprovado?</td>
        <td>{{ liveness.aware.approved === true ? 'sim' : 'não' }}</td>
    </tr>
    <tr v-if="liveness">
        <td class="font-weight-bold">Unico</td>
        <td>{{ liveness.unico }}</td>
    </tr>

    <v-card-subtitle class="font-weight-bold text-h6">Facematch</v-card-subtitle>
    <tr v-if="facematch">
        <td class="font-weight-bold">Aware</td>
        <td>{{ facematch.aware }}</td>
    </tr>
    <tr v-if="facematch">
        <td class="font-weight-bold">Unico</td>
        <td>{{ facematch.unico }}</td>
    </tr>

    <v-card-subtitle class="font-weight-bold text-h6">Valor solicitado</v-card-subtitle>
    <tr v-if="valorSolicitado">
        <td class="font-weight-bold">Tipo de documento</td>
        <td>{{ valorSolicitado.tod }}</td>
    </tr>
    <tr v-if="valorSolicitado">
        <td class="font-weight-bold">Valor solicitado</td>
        <td v-if="valorSolicitado.valorSolicitado">R${{ valorSolicitado.valorSolicitado.toFixed(2) }}</td>
        <td v-else>N/A</td>
    </tr>
    </tbody>
    </v-simple-table>
</div>
</template>

<script>
import dateFmt from '../mixins/dateFmt'

export default {
    props: {
        avals: Array,
        loading: Boolean,
    },

    mixins: [dateFmt],

    data: () => ({
        valorPedidoLabel: 'Valor de Pedido',
        OCRLabel: 'OCR',
        livenessLabel: 'Liveness',
        facematchLabel: 'Face Match',
        biometriaLabel: 'Biometria'
    }),

    computed: {
        valorSolicitado() {
            return this.avals.filter(a => a.nome_modelo===this.valorPedidoLabel)[0]?.data   
        },
        ocr() {
            return this.avals.filter(a => a.nome_modelo===this.OCRLabel)[0]?.data   
        },
        liveness() {
            return this.avals.filter(a => a.nome_modelo===this.livenessLabel)[0]?.data   
        },
        facematch() {
            return this.avals.filter(a => a.nome_modelo===this.facematchLabel)[0]?.data   
        },
        biometria() {
            return this.avals.filter(a => a.nome_modelo===this.biometriaLabel)[0]?.data   
        }
    },

    methods: {
        formatCpf(cpf) {
            return `${cpf.slice(0,3)}.${cpf.slice(3,6)}.${cpf.slice(6,9)}-${cpf.slice(9,11)}`
        }
    }

};
</script>
