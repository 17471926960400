<template>
  <v-container fluid>
    <template v-if="!loading">
      <v-row>
        <v-col>
          <v-card>
            <v-card-title>
              {{ modelo.nome }}
              <v-spacer />
              <NovoModelo
                v-if="modelo.tipo_de_modelo !== 'M'"
                :modelo="modelo"
              />
            </v-card-title>
            <v-card-text>
              <v-simple-table dense>
                <tbody>
                  <tr>
                    <td>Tipo</td>
                    <td>{{ modelo.tipo_de_modelo }}</td>
                  </tr>
                  <tr>
                    <td>Criador</td>
                    <td>{{ modelo.creator }}</td>
                  </tr>
                  <tr>
                    <td>Criado</td>
                    <td>{{ formattedDate(modelo.created) }}</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card-text>
          </v-card>
          <v-row>
            <v-col>
              <ModeloParams v-if="Object.keys(modelo.parametros).length" :params="modelo.parametros" />
            </v-col>
          </v-row>
        </v-col>
        <v-col>
          <v-card>
            <v-card-title>
              Sumário
            </v-card-title>
            <v-card-text>
              <v-simple-table dense>
                <thead>
                  <tr>
                    <th></th>
                    <th>#</th>
                    <th>%</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Pedidos Avaliados</td>
                    <td>{{ modelo.sumario.total }}</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td>Aprovados</td>
                    <td>{{ modelo.sumario.aprovados }}</td>
                    <td>{{ modelo.sumario.total ? ((modelo.sumario.aprovados/modelo.sumario.total) * 100.0).toFixed(2) : '-' }}</td>
                  </tr>
                  <tr>
                    <td>Reprovados</td>
                    <td>{{ modelo.sumario.reprovados }}</td>
                    <td>{{ modelo.sumario.total ? ((modelo.sumario.reprovados/modelo.sumario.total) * 100.0).toFixed(2) : '-' }}</td>
                  </tr>
                  <tr>
                    <td>Insuficientes</td>
                    <td>{{ modelo.sumario.insuficientes }}</td>
                    <td>{{ modelo.sumario.total ? ((modelo.sumario.insuficientes/modelo.sumario.total) * 100.0).toFixed(2) : '-' }}</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </template>
    <v-card v-else>
      <v-card-text class="text-center">
        <v-progress-circular
          indeterminate
        ></v-progress-circular>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import dateFmt from '../mixins/dateFmt'

import ModeloParams from './ModeloParams'
import NovoModelo from './NovoModelo'

export default {
  components: {
    ModeloParams, NovoModelo
  },

  props: {
    modeloid: Number,
  },

  mixins: [dateFmt],

  created () {
    this.fetchData()
  },

  data: () => ({
    loading: true,
    modelo: null,
  }),

  computed: {
    ...mapState(['modelos',]),
  },

  methods: {
    async fetchData () {
      await this.$store.dispatch('modelos/get_modelo_by_id', this.modeloid)
      this.modelo = this.$store.getters['modelos/getModeloById'](parseInt(this.modeloid))
      this.loading = false
    },
  },

};
</script>
