<template>
    <v-container fluid>
      <template v-if="bioscan">
        <v-row>
          <v-col cols='12' md='6'>
            <!-- <v-row>
              <v-col> -->
            <v-card>
              <v-card-title>
                <v-icon large class="mr-2">mdi-account-cash</v-icon> {{ bioscan.documento }}{{ displayNome }}
              </v-card-title>
              <v-card-text>
                <v-simple-table dense>
                  <tbody>
                    <tr>
                      <td class="font-weight-bold">Status</td>
                      <td v-if="bioscan.avaliado_por_mim">{{ displayStatus(bioscan.status) }}</td>
                      <td v-else class="indigo--text">Avalie a operação para ver este dado</td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-card-text>
            </v-card>
          <!-- </v-col> -->
            <!-- </v-row> -->
            <v-row>
              <v-col>
                <v-expansion-panels>
                  <v-expansion-panel @click="fetchPxdata()">
                    <v-expansion-panel-header><v-card-title><v-icon large class="ml-n6 mr-2">mdi-folder</v-icon> Resumo PXSearch</v-card-title></v-expansion-panel-header>
                    <v-expansion-panel-content v-if="bioscan.pxdata">
                      <PXSummary
                        v-if="bioscan.pxdata"
                        :bioscan="bioscan"
                        :loading="loading"
                        @summaryChanged="fetchData"
                      >
                      </PXSummary>
                    </v-expansion-panel-content>
                    <v-expansion-panel-content v-else>
                    <v-card>
                      <v-expansion-panel-header><v-card-title><v-icon large class="ml-n6 mr-2">mdi-folder</v-icon> Resumo PXSearch</v-card-title></v-expansion-panel-header>
                      <v-card-text>
                        <v-overlay
                          absolute
                          opacity="1"
                          color="white"
                          light
                          z-index="4"
                        >
                          <span class="indigo--text title">Documento não encontrado</span>
                        </v-overlay>
                      </v-card-text>
                    </v-card>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header><v-card-title><v-icon large class="ml-n6 mr-2">mdi-folder</v-icon> Dados Filescan</v-card-title></v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <DadosFilescan :avals="avals"/>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
          </v-col>
          </v-row>
          </v-col>
        <v-col cols='12' md='6'>
          <Avaliacoes :bioscan="bioscan" @fetchedAvals="fetchedAvals"/>
        </v-col>
      </v-row>
    </template>
    <v-card v-else>
      <v-card-text class="text-center">
        <v-progress-circular
          indeterminate
        ></v-progress-circular>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import dateFmt from '../mixins/dateFmt'
import displayStatus from '../mixins/displayStatus'

import PXSummary from './PXSummary'
import Avaliacoes from './Avaliacoes'
import DadosFilescan from './DadosFilescan'

export default {
  components: {
    PXSummary,
    Avaliacoes,
    DadosFilescan,
  },

  props: {
    bioscanid: Number,
  },

  mixins: [dateFmt, displayStatus],

  created () {
    this.init()
  },

  destroyed () {
    // clearInterval(this.reloader)
  },

  data: () => ({
    loading: true,
    error: null,
    reloader: null,
    avals: [],
  }),

  computed: {
    ...mapState(['bioscans','avaliacoes','account',]),

    bioscan: function() {
      return this.$store.getters['bioscans/getBioScanById'](parseInt(this.bioscanid))
    },

    finished: function() {
      // return !this.loading && this.bioscan.pxdata && !this.bioscan.pxdata.summary.pending_report_list.length
      return !this.loading && this.bioscan.pxdata
    },

    displayNome: function() {
      if(!this.bioscan.pxdata || !this.bioscan.pxdata.summary) {
        return ''
      }

      if ('nome_set' in this.bioscan.pxdata.summary &&
                    this.bioscan.pxdata.summary.nome_set.length > 0) {
        return ' - ' + this.bioscan.pxdata.summary.nome_set[0].value
      } else if ('razaosocial_set' in this.bioscan.pxdata.summary &&
                    this.bioscan.pxdata.summary.razaosocial_set.length > 0) {
        return ' - ' + this.bioscan.pxdata.summary.razaosocial_set[0].value
      }

      return ''

    },
  },

  methods: {
    init: function(){
      this.fetchData()
    },

    async fetchPxdata () {
      if (!this.bioscan.pxdata) {
        this.loading = true
        await this.$store.dispatch('bioscans/get_bioscan_pxdata', this.bioscanid)
        this.loading = false
      }
    },

    async fetchData () {
      this.loading = true
      await this.$store.dispatch('bioscans/get_bioscan', this.bioscanid)
      this.loading = false
    },

    fetchedAvals(avals) {
      this.avals = avals
    }
  },

};
</script>
