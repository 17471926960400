<template>
  <v-menu
    open-on-hover
  >
    <template v-slot:activator="{ on }">
      <span v-on="on"><v-icon color="grey">mdi-chart-bar</v-icon></span>
    </template>
      <v-card>
        <v-card-title>
          Telemetria
        </v-card-title>
        <v-card-text>
          <v-simple-table dense>
            <tbody>
              <tr>
                <td class="font-weight-bold">Criado</td>
                <td>{{ formattedDate(aval.created) }}</td>
              </tr>
              <tr>
                <td class="font-weight-bold">Primeira Tentativa</td>
                <td>{{ formattedDate(aval.first_started) }}</td>
              </tr>
              <tr>
                <td class="font-weight-bold">Última Tentativa</td>
                <td>{{ formattedDate(aval.last_started) }}</td>
              </tr>
              <tr>
                <td class="font-weight-bold">Finalizado</td>
                <td>{{ formattedDate(aval.completed) }}</td>
              </tr>
              <tr>
                <td class="font-weight-bold">Tentativas</td>
                <td>{{ aval.tries }}</td>
              </tr>
              <tr>
                <td class="font-weight-bold">Tempo de Execução</td>
                <td>{{ aval.total_execution_time }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card-text>
      </v-card>
  </v-menu>
</template>

<script>
import dateFmt from '../mixins/dateFmt'

export default {
  props: {
    aval: Object,
  },
  mixins: [dateFmt],


};
</script>
