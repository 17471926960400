<template>
  <v-list-item three-line :to="{ name: 'bioscan', params: { bioscanid: bioscan.id } }">
    <v-list-item-avatar>
      <v-icon large>mdi-account-cash</v-icon>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title class="d-flex justify-space-between">
        <div>
          {{ bioscan.documento }} - {{ bioscan.display_name || '?' }}
          <v-icon v-if="bioscan.avaliado_por_mim" color="green" small>mdi-check-circle-outline</v-icon>
          <v-icon v-if="bioscan.avaliacao_definitiva" color="yellow darken-2" small>mdi-shield-star-outline</v-icon>
        </div>
        <v-chip
          v-if="bioscan.modelo_group_obj"
          :active="bioscan.modelo_group_obj !== null"
          color="primary"
          x-small
          > {{ bioscan.modelo_group_obj.name }} </v-chip>
      </v-list-item-title>
      <v-list-item-subtitle>
        {{ displayStatus(bioscan.status) }}
      </v-list-item-subtitle>
      <v-list-item-subtitle>
        {{ bioscan.creator }}, {{ displayCreated }}
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import dateFmt from '../mixins/dateFmt'
import displayStatus from '../mixins/displayStatus'

export default {
  props: {
    bioscan: Object,
  },

  mixins: [dateFmt, displayStatus],

  computed: {
    displayCreated: function() {
      return this.formattedDate(this.bioscan.created)
    },
  }

};
</script>
