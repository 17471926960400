<template>
  <v-card>
    <v-overlay
      v-if="loading"
      absolute
      class="text-center">
      <v-progress-circular
        indeterminate
      ></v-progress-circular>
    </v-overlay>

    <v-card-title><v-icon large class="mr-2">mdi-shield-search</v-icon> Resumo PXSearch</v-card-title>
    <v-card-text>
      <v-simple-table dense>
        <tbody>
          <template v-for="sk in summaryKeys">
            <tr v-for="(n, idx) in bioscan.pxdata.summary[sk]" :key="`summary-${sk}-${n.value}`">
              <td><span v-if="idx === 0" class="font-weight-bold">{{ displaySummaryKey(sk) }}</span></td>
              <td>{{ n.label }} ({{ n.reports.length }})</td>
            </tr>
            <tr v-if="bioscan.pxdata.summary[sk].length === 0" :key="`summary-${sk}-noval`">
              <td class="font-weight-bold">{{ displaySummaryKey(sk) }}</td>
              <td class="font-weight-light">?</td>
            </tr>
          </template>
          <tr v-for="(a, idx) in bioscan.pxdata.summary.alerts" :key="`alert-${idx}`">
            <td><span v-if="idx === 0" class="font-weight-bold">Alerta(s)</span></td>
            <td><v-icon small color="orange">mdi-alert-outline</v-icon> {{ a.message }}</td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card-text>

      <v-card-title><v-icon large class="mr-2">mdi-shield-search</v-icon> Buscas PXSearch</v-card-title>
      <v-card-text>
        <ReportStatusLists
          :bioscan="bioscan"
          @summaryChanged="$emit('summaryChanged')"
        >
        </ReportStatusLists>
      <v-simple-table dense>
        <tbody>
          <tr v-for="(n, idx) in bioscan.pxdata.summary.searches" :key="`summary-searches-${idx}`">
            <td><span v-if="idx === 0" class="font-weight-bold">Links</span></td>
            <td><a :href="cleanURL(n.url)">{{ cleanURL(n.url) }}</a></td>
          </tr>
          <tr v-if="bioscan.pxdata.summary.searches.length === 0">
            <td class="font-weight-bold">Links</td>
            <td>Nenhuma busca recente para este documento</td>
          </tr>
          <tr>
            <td class="font-weight-bold">Buscas Completas</td>
            <td>
              <v-icon v-if="!bioscan.pxdata.summary.pending_report_list.length">mdi-check</v-icon>
              <v-icon v-else>mdi-close</v-icon>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import displaySummaryKey from '../mixins/displaySummaryKey'
import ReportStatusLists from './ReportStatusLists'

export default {
  components: {
    ReportStatusLists,
  },

  props: {
    bioscan: Object,
    loading: Boolean,
  },

  mixins: [displaySummaryKey],

  methods: {
    cleanURL: function(url) {
      return url.replace('api/', '')
    },
  },

  computed: {
    summaryKeys: function() {
      return Object.keys(this.bioscan.pxdata.summary).filter(function(k) {
        if(['searches', 'alerts', 'finished_report_list', 'pending_report_list', 'optional_report_list', 'failed_report_list' ].includes(k)) {
          return false
        }

        return true
      })
    },

  },
};
</script>
