import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import BioScan from './views/BioScan.vue'
import Modelo from './views/Modelo.vue'

Vue.use(Router)

function castBioScanID(route) {
  return {
    bioscanid: Number(route.params.bioscanid),
  };
}

function castModeloID(route) {
  return {
    modeloid: Number(route.params.modeloid),
  };
}

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    { path: '/', name: 'home', component: Home },
    { path: '/bioscan/:bioscanid', name: 'bioscan', component: BioScan, props: castBioScanID },
    { path: '/modelo/:modeloid', name: 'modelo', component: Modelo, props: castModeloID },
  ]
})
