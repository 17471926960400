import axios from 'axios'

const state = {
  avaliacoes: [],
  avaliacoes_prev: null,
  avaliacoes_next: null,
  avaliacoes_pg: null,
  avaliacoes_tot_pgs: null,
}

const getters = {
  getAvaliacaoByPedidoURL: (state) => (pedido) => {
    return state.avaliacoes.find(aval => aval.pedido === pedido)
  },
}

const mutations = {
  clear_avaliacoes (state) {
    state.avaliacoes = []
    state.avaliacoes_prev = null
    state.avaliacoes_next = null
    state.avaliacoes_pg = null
    state.avaliacoes_tot_pgs = null
  },

  set_avaliacoes (state, { results, prev, next, pg, tot }) {
    state.avaliacoes = results
    state.avaliacoes_prev = prev
    state.avaliacoes_next = next
    state.avaliacoes_pg = pg
    state.avaliacoes_tot_pgs = tot
  },

  add_avaliacao (state, result) {
    let idx = state.avaliacoes.findIndex(op => op.id === result.id)
    if (idx !== -1) {
      state.avaliacoes.splice(idx, 1, result)
    } else {
      state.avaliacoes.push(result)
    }
  },
}

const actions = {
  async get_avaliacoes ({ commit, rootGetters }, { bioscan, modelo, tipo, analista, url }) {
    if (!url) {
      url = process.env.VUE_APP_API_URL + `/avalsdebioscan/`

      if(bioscan) {
        url += `?pedido=${bioscan}`
      }

      if(modelo) {
        url += `?modelo=${modelo}`
      }

      if(tipo) {
        url += `?tipo=${tipo}`
      }

      if(analista) {
        url += `?analista=${analista}`
      }
    }

    let response = await axios
      .get(url,
        { headers: rootGetters['account/getAuthHeader'], },
      )

      await commit('set_avaliacoes',
        { 'results': response.data.results,
          'prev': response.data.previous,
          'next': response.data.next,
          'pg': response.data.page,
          'tot': response.data.total_pages,
        })
  },

  async new_avaliacao ({ commit, rootGetters },
      { resultado, definitivo, comentario, bioscan }) {

    let data = {
      resultado: resultado,
      definitivo: definitivo,
      comentario: comentario,
      pedido: bioscan,
    }

    let response = await axios
      .post(process.env.VUE_APP_API_URL + `/avalsdebioscan/`,
        data,
        { headers: rootGetters['account/getAuthHeader'], }
      )

    await commit('add_avaliacao', response.data)
    return response.data
  },

}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
