<template>
  <v-card>
    <v-overlay
      v-if="loading"
      absolute
      class="text-center">
      <v-progress-circular
        indeterminate
      ></v-progress-circular>
    </v-overlay>
    <v-card-title>
      <span class="mr-2">
        <v-icon x-large color="green" v-if="bioscan.resultado === 'A'">mdi-thumb-up-outline</v-icon>
        <v-icon x-large color="red" v-if="bioscan.resultado === 'R'">mdi-thumb-down-outline</v-icon>
        <v-icon x-large color="yellow darken-2" v-if="bioscan.resultado === 'I'">mdi-pencil-box-outline</v-icon>
        <v-icon x-large color="black" v-if="bioscan.resultado === 'F'">mdi-skull-crossbones-outline</v-icon>
        <v-icon x-large color="grey" v-if="!bioscan.resultado">mdi-clock-outline</v-icon>
      </span>
      <span>Avaliações</span>
      <v-spacer />
      <v-btn
        x-small
        outlined
        fab
        color="indigo"
        @click="reavaliar"
        class="mr-2"
      >
          <v-icon>mdi-sync</v-icon>
      </v-btn>
      <NovaAvaliacao
        v-if="!bioscan.avaliado_por_mim || !bioscan.avaliacao_definitiva"
        :bioscan="bioscan"
        @avalCreated="fetchData()"
      />
    </v-card-title>
    <v-card-text>
      <v-container>

        <v-row v-for="(m, i) in sortedAvals" :key="i">
          <v-col>
            <!-- <Avaliacao :avaliacao="m" :obfuscado="!bioscan.avaliado_por_mim" /> -->
            <Avaliacao :avaliacao="m" :obfuscado="false" />
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-btn text
        :disabled="avaliacoes.avaliacoes_prev === null"
        @click="fetchData(avaliacoes.avaliacoes_prev)"
      >
        <v-icon>mdi-chevron-left</v-icon> Anterior
      </v-btn>
      <v-spacer></v-spacer>
      {{ avaliacoes.avaliacoes_pg }} / {{ avaliacoes.avaliacoes_tot_pgs }}
      <v-spacer></v-spacer>
      <v-btn text
        :disabled="avaliacoes.avaliacoes_next === null"
        @click="fetchData(avaliacoes.avaliacoes_next)"
      >
        Próximo <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'

import Avaliacao from './Avaliacao'
import NovaAvaliacao from './NovaAvaliacao'

export default {
  components: {
    Avaliacao, NovaAvaliacao
  },

  created () {
    this.fetchData()
  },

  data: () => ({
    loading: true,
    error: null,
  }),

  props: {
    bioscan: Object,
  },

  computed: {
    ...mapState(['avaliacoes', 'account']),
    sortedAvals() {
      const order = {
        null: 0,
        'I': 1,
        'R': 2,
        'F': 3,
        'A': 4
      }
      return this.avaliacoes.avaliacoes.slice().sort((a,b)=>order[a.resultado]-order[b.resultado])
    }
  },

  methods: {
    fetchData (url) {
      this.loading = true
      this.error = null

      this.$store.dispatch('avaliacoes/get_avaliacoes', { bioscan: this.bioscan.id, url: url })
      .then(() => {
        this.loading = false
        this.$emit('fetchedAvals', this.avaliacoes.avaliacoes)
      })
      .catch((error) => {
        this.error = error.response.data
      })
    },

    reavaliar() {
      this.$store.dispatch('bioscans/reaval_bioscan', this.bioscan.id )
      .then(() => {
        this.fetchData()
      })
      .catch((error) => {
        this.error = error.response
      })
    }
  }

};
</script>
