<template>
  <v-card>
    <v-card-title>BioScans</v-card-title>
    <v-overlay
      v-if="loading"
      absolute
      class="text-center">
      <v-progress-circular
        indeterminate
      ></v-progress-circular>
    </v-overlay>
    <div>
      <div v-for="(s, i) in bioscans.bioscans" :key="i">
        <BioScanListItem :bioscan="s">
        </BioScanListItem>
        <v-divider
          v-if="i + 1 < bioscans.bioscans.length"
          :key="i"
        ></v-divider>
      </div>
    </div>
    <v-card-actions>
      <v-btn text
        :disabled="bioscans.bioscans_prev === null"
        @click="fetchData(bioscans.bioscans_prev)"
      >
        <v-icon>mdi-chevron-left</v-icon> Anterior
      </v-btn>
      <v-spacer></v-spacer>
      {{ bioscans.bioscans_pg }} / {{ bioscans.bioscans_tot_pgs }}
      <v-spacer></v-spacer>
      <v-btn text
        :disabled="bioscans.bioscans_next === null"
        @click="fetchData(bioscans.bioscans_next)"
      >
        Próximo <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'

import BioScanListItem from './BioScanListItem'

export default {
  components: {
    BioScanListItem,
  },

  created () {
    this.fetchData()
  },

  data: () => ({
    loading: false,
  }),

  computed: mapState([
    'bioscans', 'account',
  ]),

  methods: {
    async fetchData (url) {
      this.loading = true
      await this.$store.dispatch('bioscans/get_bioscans', url)
      this.loading = false

    }
  }

};
</script>
