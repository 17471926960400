<template>
  <div>
    <AppNav />
    <v-main>
      <BioScanDetails
        v-if="$store.getters['account/loggedin']"
        :bioscanid="bioscanid">
      </BioScanDetails>
      <v-container v-else fluid grid-list-md>
        <h1>Por favor, faça login para usar o sistema.</h1>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import AppNav from '../components/AppNav';
import BioScanDetails from '../components/BioScanDetails';

export default {
  components: {
    AppNav,
    BioScanDetails,
  },

  props: {
    bioscanid: Number,
  },

  computed: mapState([
    'account'
  ]),
};
</script>
