import axios from 'axios'

const state = {
  bioscans: [],
  bioscans_prev: null,
  bioscans_next: null,
  bioscans_pg: null,
  bioscans_tot_pgs: null,
}

const getters = {
  getBioScanById: (state) => (id) => {
    return state.bioscans.find(op => op.id === id)
  },
}

const mutations = {
  clear_bioscans (state) {
    state.bioscans = []
    state.bioscans_prev = null
    state.bioscans_next = null
    state.bioscans_pg = null
    state.bioscans_tot_pgs = null
  },

  set_bioscans (state, { results, prev, next, pg, tot }) {
    state.bioscans = results
    state.bioscans_prev = prev
    state.bioscans_next = next
    state.bioscans_pg = pg
    state.bioscans_tot_pgs = tot
  },

  add_bioscan (state, result) {
    let idx = state.bioscans.findIndex(op => op.id === result.id)
    if (idx !== -1) {
      state.bioscans.splice(idx, 1, result)
    } else {
      state.bioscans.push(result)
    }
  },
}

const actions = {
  async get_bioscans ({ commit, rootGetters }, url) {
    if (!url) {
      url = process.env.VUE_APP_API_URL + `/pedidosdebioscan/`
    }

    let response = await axios
      .get(url,
        { headers: rootGetters['account/getAuthHeader'], },
      )

      await commit('set_bioscans',
        { 'results': response.data.results,
          'prev': response.data.previous,
          'next': response.data.next,
          'pg': response.data.page,
          'tot': response.data.total_pages,
        })
  },

  async getResource ({ rootGetters }, url) {
    let response = await axios
      .get(url,
        { headers: rootGetters['account/getAuthHeader'], }
      )

    return response.data
  },

  async get_bioscan_pxdata ({ dispatch, commit }, id) {
    let url = process.env.VUE_APP_API_URL + `/pedidosdebioscan/${id}/?pxdata=1`
    let response = await dispatch('getResource', url)
    await commit('add_bioscan', response)
    return response
  },

  async get_bioscan ({ dispatch, commit }, id) {
    let url = process.env.VUE_APP_API_URL + `/pedidosdebioscan/${id}/`
    let response = await dispatch('getResource', url)
    await commit('add_bioscan', response)
    return response
  },

  async reaval_bioscan ({ rootGetters }, id) {
    let url = process.env.VUE_APP_API_URL + `/pedidosdebioscan/${id}/reavaliar/`
    let response = await axios
      .post(url,
        null,
        { headers: rootGetters['account/getAuthHeader'], }
      )

    return response.data
  },

  async retry_failed_reports_bioscan ({ rootGetters }, id) {
    let url = process.env.VUE_APP_API_URL + `/pedidosdebioscan/${id}/retry_failed_reports/`
    let response = await axios
      .post(url,
        null,
        { headers: rootGetters['account/getAuthHeader'], }
      )

    return response.data
  },


}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
