<template>
  <v-card>
    <v-overlay
      v-if="obfuscado"
      absolute
      opacity="1"
      color="white"
      light
      z-index="4"
    >
      <span class="indigo--text title">Avalie o pedido para ver este dado</span>
    </v-overlay>
    <v-card-title>
      <div class="mr-2">
        <v-icon x-large color="green" v-if="avaliacao.resultado === 'A'">mdi-thumb-up-outline</v-icon>
        <v-icon x-large color="red" v-if="avaliacao.resultado === 'R'">mdi-thumb-down-outline</v-icon>
        <v-icon x-large color="yellow darken-2" v-if="avaliacao.resultado === 'I'">mdi-pencil-box-outline</v-icon>
        <v-icon x-large color="black" v-if="avaliacao.resultado === 'F'">mdi-skull-crossbones-outline</v-icon>
        <v-icon x-large color="grey" v-if="!avaliacao.resultado">mdi-clock-outline</v-icon>
      </div>
      <span v-if="avaliacao.definitivo">
        <v-icon
          color="yellow darken-2"
          class="mr-2"
        >mdi-shield-star-outline
        </v-icon>Avaliação Definitiva (
      </span>
        {{ avaliacao.nome_modelo }}
      <span v-if="avaliacao.definitivo">)</span>
    </v-card-title>
    <v-card-text>
      <div v-if="avaliacao.comentario" class="font--bold">{{ avaliacao.comentario }}</div>
      <div v-if="avaliacao.exception">
        <span
          v-if="avaliacao.resultado==='F'"
          class="red--text"
        >
          {{avaliacao.exception}}
        </span>
        <span 
          v-if="avaliacao.resultado==='N' || avaliacao.resultado===null"
          class="blue-grey--text"
        >
          {{avaliacao.exception}}
        </span>
      </div>
      <div v-for="(comentario, idx) in avaliacao.comentarios" :key="idx">{{comentario}}</div>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <AvalTelemetry :aval="avaliacao" />
    </v-card-actions>
  </v-card>
</template>

<script>
import AvalTelemetry from "./AvalTelemetry";
export default {
  components: {
    AvalTelemetry,
  },

  props: {
    avaliacao: Object,
    obfuscado: Boolean,
  },
};
</script>
