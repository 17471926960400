export default {
  methods: {
    displayStatus: function(status) {
      switch(status) {
        case "N":
          return "Analisando"
        case "I":
          return "Insuficiente"
        case "R":
          return "Reprovado"
        case "A":
          return "Aprovado"
        default:
          return "Status desconhecido"
      }
    },
  },
}
