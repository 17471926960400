<template>
  <v-container fluid>
    <v-row>
      <v-col md6>
        <BioScanList />
      </v-col>
      <v-col md6>
        <ModeloList tipo="C"/>
        <v-row>
          <v-col>
            <ModeloList tipo="M"/>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BioScanList from './BioScanList'
import ModeloList from './ModeloList'

export default {
  components: {
    BioScanList, ModeloList,
  },
};
</script>
