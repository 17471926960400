<template>
  <v-dialog v-model="dialog" width="600">
    <template v-slot:activator="{ on }">
      <v-btn x-small outlined fab color="indigo" v-on="on"><v-icon>mdi-plus</v-icon></v-btn>
    </template>
      <v-card>
        <v-card-title>
          Adicionar Avaliação
        </v-card-title>
        <v-card-text>
          <v-form>
            <div class="d-flex justify-space-around mt-2 mb-5">
              <div><v-btn :outlined="resultado != 'R'" fab :color="resultado === 'R' ? 'red' : 'grey'" height="64" width="64" dark
                @click="resultado = 'R'"
              >
                <v-icon size="40">mdi-thumb-down-outline</v-icon>
              </v-btn><div class="text-center mt-1">Reprovado</div></div>
              <div><v-btn :outlined="resultado != 'I'" fab :color="resultado === 'I' ? 'yellow darken-2' : 'grey'" height="64" width="64" dark
                @click="resultado = 'I'"
              >
                <v-icon size="40">mdi-file-question-outline</v-icon>
              </v-btn><div class="text-center mt-1">Insuficiente</div></div>
              <div><v-btn :outlined="resultado != 'A'" fab :color="resultado === 'A' ? 'green' : 'grey'" height="64" width="64" dark
                @click="resultado = 'A'"
              >
                <v-icon size="40">mdi-thumb-up-outline</v-icon>
              </v-btn><div class="text-center mt-1">Aprovado</div></div>
            </div>
              <v-textarea
                outlined
                v-model="comentario"
                label="Comentário"
                :error-messages="errors.comentario"
                rows="15"
              ></v-textarea>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-checkbox
            class="ml-3"
            label="Avaliação Definitiva"
            v-model="definitivo"
            @click.native="definitivo ? definitivoDialog = true : null"
          ></v-checkbox>
          <v-spacer />
          <v-btn text @click="dialog = false">Cancelar</v-btn>
          <v-btn
            text
            @click="createAval()"
            :disabled="resultado === null"
          >Salvar</v-btn>
        </v-card-actions>
      </v-card>
      <v-dialog
        persistent
        v-model="definitivoDialog"
        max-width="400"
      >
        <v-card>
          <v-card-title>Avaliação Definitiva</v-card-title>
          <v-card-text>Marcar esta avaliação como definitiva pode desencadear desembolsos para a Upp. Certifique-se de que esta avaliação é correta antes de continuar.</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="definitivo = false; definitivoDialog = false">Cancelar</v-btn>
            <v-btn text @click="definitivo = true; definitivoDialog = false">Continuar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-dialog>
</template>

<script>
export default {
  props: {
    bioscan: Object,
  },

  data: function() {
     return {
        dialog: false,
        definitivoDialog: false,

        resultado: null,
        definitivo: false,
        comentario: null,

        errors: {
          resultado: null,
          comentario: null,
        },

        non_field_errors: null,
      }
  },

  methods: {
    createAval: async function() {
        this.errors = {}
        this.non_field_errors = null

        await this.$store.dispatch('avaliacoes/new_avaliacao',
          {
            resultado: this.resultado,
            definitivo: this.definitivo,
            comentario: this.comentario,
            bioscan: this.bioscan.url,
          })

        this.dialog = false
        this.$store.dispatch('bioscans/get_bioscan', this.bioscan.id)
        this.$emit('avalCreated')
    },
  },

};
</script>
