import Vue from 'vue'
import Vuex from 'vuex'

import account from './modules/account'
import bioscans from './modules/bioscans'
import modelos from './modules/modelos'
import avaliacoes from './modules/avaliacoes'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    account: account,
    bioscans: bioscans,
    modelos: modelos,
    avaliacoes: avaliacoes,
  },
})
