<template>
  <v-card>
    <v-card-title v-if="tipo === 'C'">Modelos Matemáticos</v-card-title>
    <v-card-title v-if="tipo === 'M'">Analistas</v-card-title>
    <v-overlay
      v-if="loading"
      absolute
      class="text-center">
      <v-progress-circular
        indeterminate
      ></v-progress-circular>
    </v-overlay>
    <div>
      <div v-for="(m, i) in targetList.list" :key="i">
        <ModeloListItem :modelo="m">
        </ModeloListItem>
        <v-divider
          v-if="i + 1 < targetList.list.length"
          :key="i"
        ></v-divider>
      </div>
    </div>
    <v-card-actions>
      <v-btn text
        :disabled="targetList.prev === null"
        @click="fetchData(targetList.prev)"
      >
        <v-icon>mdi-chevron-left</v-icon> Anterior
      </v-btn>
      <v-spacer></v-spacer>
      {{ targetList.pg }} / {{ targetList.tot_pgs }}
      <v-spacer></v-spacer>
      <v-btn text
        :disabled="targetList.next === null"
        @click="fetchData(targetList.next)"
      >
        Próximo <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'

import ModeloListItem from './ModeloListItem'

export default {
  components: {
    ModeloListItem,
  },

  props: {
    tipo: String,
  },

  created () {
    this.fetchData()
  },

  data: () => ({
    loading: false,
  }),

  computed: {
    ...mapState(['modelos',]),
    targetList: function() {
      if(this.tipo === 'M') {
        return this.modelos.analistas
      } else {
        return this.modelos.modelos
      }
    },
  },

  methods: {
    fetchData (url) {
      this.loading = true
      this.error = null

      let task = ''
      if(this.tipo === 'M') {
        task = 'modelos/get_analistas'
      } else {
        task = 'modelos/get_modelos'
      }

      this.$store.dispatch(task, url)
      .then(() => {
        this.loading = false
      })
      .catch((error) => {
        this.error = error.response.data
      })
    }
  }

};
</script>
